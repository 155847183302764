import { useEffect } from "react";
import { AxiosError } from "axios";
import { QueryClient, QueryClientProvider } from "react-query";

import { Providers } from "@/provider";
import { useLogout } from "@/hooks/utils/useLogout";
import { authAxios } from "@/utils/utils";

import type { AppProps } from "next/app";

import "../styles/globals.scss";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const logout = useLogout();

  useEffect(() => {
    authAxios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) logout();
      }
    );
  }, [logout]);

  return (
    <QueryClientProvider client={queryClient}>
      <Providers>
        <Component {...pageProps} />
      </Providers>
    </QueryClientProvider>
  );
}

export default MyApp;
