import axios from "axios";
import { getCookie } from "cookies-next";

const switchToHTTPS = (url: string) => url.replace(/^http:/, "https:");

const getAuthToken = () => getCookie("ecn.client.auth.token");
export const authAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

export async function authGetter(url: string) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .get(switchToHTTPS(url))
    .then((response) => response);
  return response?.data;
}

export async function authPoster(url: string, data) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .post(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}

export async function authDeleter(url: string, data = {}) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .delete(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}

export async function authPutter(url: string, data) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .put(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}
