import { useRouter } from "next/router";
import { useCallback } from "react";
import { deleteCookie } from "cookies-next";

export function useLogout() {
  const router = useRouter();

  return useCallback(() => {
    localStorage.clear();
    deleteCookie("ecn.client.auth.token");
    router.push("/login");
  }, [router]);
}
